<template>
	<div class="bar-wapper">
		<div class="bar-title">报名流程</div>
		<div class="bar-list">
			<el-steps direction="vertical" :active="active" space="60px">
				<el-step title="阅读诚信考试承诺书"></el-step>
				<el-step title="填写个人信息"></el-step>
				<el-step title="校对报名信息"></el-step>
				<el-step title="完成报名"></el-step>
			</el-steps>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			active: 0
		}
	}
</script>
<style lang="scss" scoped>
	.bar-title {
		color: #1990ff;

	}

	.bar-wapper {
		padding: 20px 16px;
		background: #f8f8f8;
	}

	.bar-list {
		padding-top: 20px;

		::v-deep .el-step__title {
			font-size: 13px;
		}

		::v-deep .el-step__line {
			border-right: 1px dashed #ccc;
			background: none;
			width: 1px;
			top: 6px;
			bottom: 0;
			left: 3px;
		}

		::v-deep .el-step__line-inner {
			border: none;
		}

		::v-deep .el-step__icon {
			width: 10px;
			height: 10px;
			font-size: 0;
			top: -3px;
		}

		::v-deep .el-step__main {
			padding-left: 0;
		}

		::v-deep .el-step__head.is-finish {
			border-color: #101010;
		}

		::v-deep .el-step__title.is-finish {
			color: #101010;
		}

		::v-deep .el-step__head.is-wait {
			border-color: #101010;
		}

		::v-deep .el-step__title.is-wait {
			color: #101010;
		}

		::v-deep .el-step__head.is-process {
			border-color: #ff670e;
		}

		::v-deep .el-step__title.is-process {
			color: #ff670e;
		}
	}
</style>
